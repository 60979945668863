import React from "react";
import {Icon} from "@iconify/react";

export const Features = (props) => {
	return (
		<div id="features" className="text-center" style={{paddingBottom: "50px"}}>
			<div className="container" style={{paddingTop: "50px"}}>
				<div className="col-md-10 col-md-offset-1 section-title">
					<h2>Features</h2>
				</div>

				<div className="row">
					{props.data
						? props.data.map((d, i) => (
							<div key={`${d.title}-${i}`} className="col-xs-8 col-md-4">
								{" "}
								{/*<i className={d.icon}></i>*/}
								<Icon icon={d.icon} style={{ fontSize: '10em' }} />
								<h3>{d.title}</h3>
								<p>{d.text}</p>
							</div>
						))
						: "Loading..."}
				</div>
			</div>
		</div>
	);
};
